import React, {useContext, useEffect, useState} from 'react'
import { Success } from '../../Components/Buttons/Buttons'
import LoginForm from '../../Components/LoginForm/LoginForm'
import Popup from '../../Components/Popup/Popup'
import { usuarioContext } from '../../context/userContext'
import Header from '../../Components/Header/Header'
import Loader from '../../Components/Loader/Loader'

import './Login.css'

const Login = () => {
  const {dataForm, getLogin, Allow,popPupMensajeLogin,setPopPupMensajeLogin} = useContext(usuarioContext)
  //const[popPupMensajeLogin, setPopPupMensajeLogin] = useState(false)

  useEffect(()=>{

    //setPermitir(Allow)
    console.log(" LoginAllow " + Allow)
    console.log("Permitir " + popPupMensajeLogin)

  },[Allow])
  return (
    <div className="pageLogin">
       <LoginForm send={getLogin} change={dataForm} />
       {
         (Allow&&!popPupMensajeLogin)||(!Allow&&!popPupMensajeLogin)
         ?<></>
         :
          <Popup>
            <Header Text="Alias o Contraseña incorrecta...!"/>
            <Loader/>
            <div className="d-100 comboBTNS">
              <Success Text="OK" F_Click={()=>setPopPupMensajeLogin(false)}/>
            </div>
          </Popup>
       }
    </div>
  )
}

export default Login